// Gradient Animation Manager
export class GradientAnimator {
  constructor(element, options = {}) {
    this.element = element;
    this.curX = 0;
    this.curY = 0;
    this.targetX = 0;
    this.targetY = 0;
    this.rafId = null;
    this.isAnimating = false;

    // Animation options with defaults
    this.options = {
      easing: options.easing || 20,
      useTransform3d: options.useTransform3d !== false,
      roundValues: options.roundValues !== false,
    };

    // Bind methods
    this.update = this.update.bind(this);
    this.continueAnimation = this.continueAnimation.bind(this);
  }

  update(x, y) {
    this.targetX = x;
    this.targetY = y;
  }

  start() {
    if (this.isAnimating) return;
    this.isAnimating = true;
    this.continueAnimation();
  }

  continueAnimation() {
    if (!this.isAnimating || !this.element) return;

    // Calculate new positions with easing
    this.curX += (this.targetX - this.curX) / this.options.easing;
    this.curY += (this.targetY - this.curY) / this.options.easing;

    // Round values if needed
    const x = this.options.roundValues ? Math.round(this.curX) : this.curX;
    const y = this.options.roundValues ? Math.round(this.curY) : this.curY;

    // Use transform3d for better performance
    const transform = this.options.useTransform3d
      ? `translate3d(${x}px, ${y}px, 0)`
      : `translate(${x}px, ${y}px)`;

    // Apply the transform
    this.element.style.transform = transform;

    this.rafId = requestAnimationFrame(this.continueAnimation);
  }

  stop() {
    this.isAnimating = false;
    if (this.rafId) {
      cancelAnimationFrame(this.rafId);
      this.rafId = null;
    }
  }

  destroy() {
    this.stop();
    this.element = null;
  }
}
