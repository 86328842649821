// Import required function
import { Helpers } from "./helpers.js";

// Optimized Parallax Manager
class ParallaxManager {
  constructor() {
    // Cache layer references and depths once
    this.layers = Array.from(
      document.querySelectorAll('[data-type="parallax"]')
    ).map((layer) => ({
      element: layer,
      depth: parseFloat(layer.getAttribute("data-depth")),
      originalDepth: parseFloat(layer.getAttribute("data-depth")), // Store original depth
    }));

    // Cache DOM lookups
    this.documentElement = document.documentElement;

    // Bind methods
    this.onScroll = this.onScroll.bind(this);
    this.update = this.update.bind(this);
    this.onResize = this.onResize.bind(this);

    // Track state
    this.lastScrollTop = 0;
    this.ticking = false;
    this.hasInvokedFirstScroll = false;

    // Initialize depths
    this.updateDepthsForScreenHeight();
  }

  async init() {
    // Add event listeners
    window.addEventListener("scroll", this.onScroll, { passive: true });
    window.addEventListener("resize", this.onResize, { passive: true });

    // add delay and then add ready class
    await Helpers.delay(2800);
    document.body.classList.add("ready");
  }

  onResize() {
    this.updateDepthsForScreenHeight();
    this.update();
  }

  updateDepthsForScreenHeight() {
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    let scale = 1;

    if (viewportHeight < 1080) {
      if (viewportHeight <= 400) {
        scale = 0.1; // Minimal movement below 400px
      } else {
        const range = 1080 - 400;
        const position = viewportHeight - 400;
        scale = 0.1 + (position / range) * 0.9;
      }
    }

    this.layers.forEach((layer) => {
      // Special handling for mountain layer
      if (layer.element.classList.contains("splash-mountain")) {
        // More aggressive scaling for wider screens
        if (viewportWidth >= 1978) {
          // Lock movement to very minimal at exactly 1978px
          layer.depth = Math.min(layer.originalDepth * scale, 0.08);
        } else if (viewportWidth >= 1600) {
          // Start reducing movement earlier
          const widthScale = 1 - ((viewportWidth - 1600) / 378) * 0.5;
          layer.depth = layer.originalDepth * scale * widthScale;
        } else {
          layer.depth = layer.originalDepth * scale;
        }
      } else {
        layer.depth = layer.originalDepth * scale;
      }
    });
  }

  onScroll() {
    this.lastScrollTop = window.scrollY;

    // Request animation frame only if we're not already updating
    if (!this.ticking) {
      requestAnimationFrame(this.update);
      this.ticking = true;
    }
  }

  async handleScrollToBottom() {
    if (
      !this.hasInvokedFirstScroll &&
      document.body.classList.contains("show-ai")
    ) {
      this.hasInvokedFirstScroll = true;
      document.body.classList.add("scrolled");

      // delay for 500ms and open bottom container
      await Helpers.delay(500);
      const scrolledElement = document.querySelector(".scrolled");
      if (scrolledElement) {
        scrolledElement.scrollTop = scrolledElement.scrollHeight;
      }

      // delay for 100ms and show about container
      await Helpers.delay(100);
      const aboutContainer = document.querySelector(".about-container");
      if (aboutContainer) {
        aboutContainer.classList.add("active");
      }
    }
  }

  update() {
    const { scrollTop, scrollHeight, clientHeight } = this.documentElement;

    // Update parallax positions
    this.layers.forEach(({ element, depth }) => {
      const movement = -(this.lastScrollTop * depth);
      element.style.transform = `translate3d(0, ${movement}px, 0)`;
    });

    // Check if scrolled to bottom
    if (scrollTop + clientHeight >= scrollHeight - 155) {
      this.handleScrollToBottom();
    }

    this.ticking = false;
  }

  destroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.onResize);
  }
}

// Export for use in app.js
export function initParallax() {
  const parallax = new ParallaxManager();
  parallax.init();
  return parallax;
}
