/**
 * Modern utility functions for string and data manipulation
 */
export const Helpers = {
  /**
   * Check if a value is defined and not null
   */
  isDefined(value) {
    return value !== undefined && value !== null;
  },

  /**
   * Safely parse JSON string
   */
  parseJSON(str) {
    try {
      return JSON.parse(str);
    } catch {
      return null;
    }
  },

  /**
   * Convert string to camelCase
   */
  toCamelCase(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
        index === 0 ? word.toLowerCase() : word.toUpperCase()
      )
      .replace(/\s+/g, "");
  },

  /**
   * Clean and normalize string (lowercase, no spaces)
   */
  normalizeString(str) {
    return str.toLowerCase().replace(/\s+/g, "");
  },

  /**
   * Get URL query parameter value
   */
  getQueryParam(key) {
    const params = new URLSearchParams(window.location.search);
    return params.get(key);
  },

  /**
   * Set URL query parameters
   */
  setQueryParams(params) {
    const urlParams = new URLSearchParams(window.location.search);
    Object.entries(params).forEach(([key, value]) => {
      if (value === null) {
        urlParams.delete(key);
      } else {
        urlParams.set(key, value);
      }
    });
    return urlParams.toString();
  },

  /**
   * Decode HTML entities
   */
  decodeHTML(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  },

  /**
   * Format date to locale string with options
   */
  formatDate(date, options = {}) {
    return new Date(date).toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      ...options,
    });
  },

  /**
   * Debounce function
   */
  debounce(func, wait = 300) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  },

  /**
   * Throttle function
   */
  throttle(func, limit = 300) {
    let inThrottle;
    return function executedFunction(...args) {
      if (!inThrottle) {
        func(...args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  },

  /**
   * Create a promise that resolves after specified delay
   */
  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
};
