export class MouseTracker {
  constructor() {
    this.tgX = 0;
    this.tgY = 0;
    this.isUpdating = false;
    this.onMove = this.onMove.bind(this);
    this.callbacks = new Set();
  }

  onMove(event) {
    if (!this.isUpdating) {
      requestAnimationFrame(() => {
        this.tgX = event.clientX;
        this.tgY = event.clientY;
        this.isUpdating = false;
        // Notify all callbacks of the new position
        this.callbacks.forEach((callback) => callback(this.tgX, this.tgY));
      });
      this.isUpdating = true;
    }
  }

  subscribe(callback) {
    this.callbacks.add(callback);
    return () => this.callbacks.delete(callback);
  }

  init() {
    window.addEventListener("mousemove", this.onMove, { passive: true });
  }

  destroy() {
    window.removeEventListener("mousemove", this.onMove);
    this.callbacks.clear();
  }
}
