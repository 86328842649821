// Cleanup Manager
export class CleanupManager {
  constructor() {
    this.cleanupTasks = new Set();
  }

  // Add a cleanup task
  add(task) {
    if (typeof task === "function") {
      this.cleanupTasks.add(task);
      // Return function to remove this specific task
      return () => this.cleanupTasks.delete(task);
    }
  }

  // Add multiple cleanup tasks
  addAll(tasks) {
    tasks.forEach((task) => this.add(task));
  }

  // Run all cleanup tasks
  cleanup() {
    this.cleanupTasks.forEach((task) => {
      try {
        task();
      } catch (error) {
        console.error("Cleanup task failed:", error);
      }
    });
    this.cleanupTasks.clear();
  }

  // Clear all tasks without running them
  clear() {
    this.cleanupTasks.clear();
  }
}
